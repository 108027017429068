import React, { Component, Fragment } from "react";
import firebase, { firestore } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { navigate } from "gatsby";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Navbar,
  NavbarBrand,
} from "reactstrap";

class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      displayName: null,
      highlightedListingsCodes: [],
    };

    this.loadHighlightedListings = this.loadHighlightedListings.bind(this);
    this.onSavingHighlightedListings = this.onSavingHighlightedListings.bind(this);
  }

  componentDidMount() {
    const config = {
      apiKey: process.env.FIREBASE_API_KEY,
      authDomain: process.env.FIREBASE_AUTH_DOMAIN,
      projectId: process.env.FIREBASE_PROJECT_ID,
      storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    };
    // Check if app has already been initialized
    if (firebase.apps.length === 0) {
      firebase.initializeApp(config);
    }

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          currentUser: user,
          displayName: user.email,
        });

        this.loadHighlightedListings();
      } else {
        // not authenticated? Redirect to login
        navigate("/login");
      }
    });
  }

  onSavingHighlightedListings(event) {
    event.preventDefault();

    const data = new FormData(event.target);
    const newValues = [data.get("listing1"), data.get("listing2"), data.get("listing3")];

    // Store new values as items and previous values
    const db = firebase.firestore();
    db.collection("listings").doc("highlighted").set({
      items: newValues,
      previous: this.state.highlightedListingsCodes,
    }).then(() => {
      alert("Las propiedades destacadas se guardaron exitosamente.");
      console.log("highlighted listings saved successfuly");
    }).catch(error => console.log(error));

    // TODO: save changes to firestore
    this.setState({ highlightedListingsCodes: newValues });
  }

  loadHighlightedListings() {
    const db = firebase.firestore();
    const docRef = db.collection("listings").doc("highlighted");

    docRef.get()
      .then(doc => doc.data().items)
      .then((listingsCodes) => {
        this.setState({ highlightedListingsCodes: listingsCodes });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const {
      currentUser, displayName, highlightedListingsCodes,
    } = this.state;

    return (
      <Fragment>
        {currentUser && (
          <Fragment>
            <Navbar color="dark">
              <NavbarBrand href="/admin">Admin Web</NavbarBrand>
              <small className="navbar-text text-light">{displayName}</small>
            </Navbar>
            <Container>
              <Row className="mt-5 p-3 bg-light rounded shadow">
                <Col sm="12">
                  <h5>Propiedades destacadas</h5>
                  <p>
                    En esta secci&oacute;n puede configurar las propiedades que
                    ser&aacute;n mostradas en la p&aacute;gina principal.
                  </p>
                  <p>
                    <small>
                      Los cambios pueden tardar aprox. 30 minutos en hacer
                      efecto.
                    </small>
                  </p>
                </Col>
                <Col>
                  <Form onSubmit={this.onSavingHighlightedListings}>
                    <Row>
                      <FormGroup>
                        <Col>
                          <Label>C&oacute;digo propiedad 1:</Label>
                          <Input
                            type="text"
                            name="listing1"
                            maxLength="5"
                            defaultValue={highlightedListingsCodes[0]}
                            required
                          />
                          <FormText>
                            <a
                              href={`/inmueble?codpro=${highlightedListingsCodes[0]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Ver propiedad
                              {" "}
                              {highlightedListingsCodes[0]}
                            </a>
                          </FormText>
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col>
                          <Label>C&oacute;digo propiedad 2:</Label>
                          <Input
                            type="text"
                            name="listing2"
                            maxLength="5"
                            defaultValue={highlightedListingsCodes[1]}
                            required
                          />
                          <FormText>
                            <a
                              href={`/inmueble?codpro=${highlightedListingsCodes[1]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Ver propiedad
                              {" "}
                              {highlightedListingsCodes[1]}
                            </a>
                          </FormText>
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col>
                          <Label>C&oacute;digo propiedad 3:</Label>
                          <Input
                            type="text"
                            name="listing3"
                            maxLength="5"
                            defaultValue={highlightedListingsCodes[2]}
                            required
                          />
                          <FormText>
                            <a
                              href={`/inmueble?codpro=${highlightedListingsCodes[2]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Ver propiedad
                              {" "}
                              {highlightedListingsCodes[2]}
                            </a>
                          </FormText>
                        </Col>
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup>
                        <Col sm="12">
                          <Button type="submit" color="primary">Guardar</Button>
                        </Col>
                      </FormGroup>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default AdminPage;
